.datex-navigation .sf-menu > li > a:hover {
  color: #0e723b;
}

.datex-navigation .sf-menu > li > a:focus {
  color: #0e723b;
}

// .datex-navigation .sf-menu > li:first-child > a {
//   color: #0e723b;
// }
